<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBRow>
        <MDBCol class="mb-2 text-start" md="2">
          <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="language.selected"></MDBSelect>
        </MDBCol>
        <!-- <MDBCol class="mb-2 text-end">
          <MDBBtn color="info" @click="add_dialog = true">{{ $t("CONTENT.ADD-ITEM") }}</MDBBtn>  
        </MDBCol> -->
      </MDBRow>
      <MDBContainer fluid class="form">
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:lang="{ item }">
            {{ $t("LANG." + item.lang) }}
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:type="{ item }">
            {{ $t(`COMMON.${item.type}`) }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('CONTENT.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('CONTENT.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  
  <!-- <ContentForm v-model="add_dialog" action="insert"></ContentForm> -->
  <ContentForm v-model="edit_dialog" action="update" v-model:uuid="EditUUid"></ContentForm>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol, MDBBtn, MDBSelect } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import ContentForm from '@/components/Form/Content/ContentForm';

export default {
  name: "content-manage",
  components: {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    DataTable,
    Breadcrumbs,
    PageLoading,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBSelect,
    ContentForm
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const EditUUid = ref("");
    const desserts = ref([]);
    const lang = i18nService.getActiveLanguage();
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no"
        ,class: "text-center"
        ,width:"50px"
      },
      { text: i18n.t("COMMON.LANGUAGE"), value: "lang" ,class: "text-center"},
      { text: i18n.t("COMMON.NAME"), value: "type" ,class: "text-center"},
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" ,class: "text-center"},
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center",width:"50px"},
      // { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,class: "text-center",width:"50px"}
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Content",
          {
            params: Object.assign(CommonService.SortHandleObject(options),{lang:language.selected})
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };


    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Content/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    return {
      PageLoading,
      add_dialog,
      edit_dialog,
      loading,
      lang,
      total,
      Common,
      options,
      headers,
      desserts,
      EditUUid,
      language,
      GetTable,
      DeleteAction
    };
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
    "language.selected": function(){
      this.GetTable();
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>
